import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UnitDetailComponent } from '@app/routes/units/unit-detail/unit-detail.component';
import { UnitCobrowseModalComponent } from '@app/shared/components/unit-cobrowse-modal/unit-cobrowse-modal.component';
import { Observable } from 'rxjs';

@Injectable()
export class CobrowseSessionGuardService  {

  constructor(public router: Router) {}

  canDeactivate(
    component: UnitCobrowseModalComponent,
  ): Observable<boolean> | boolean {
    return !component.ctxCobrowse;
  }
}